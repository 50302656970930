import { WhoIssuesPolicy } from '@entities/import-smart-components/who-Issues-policy';
import { FallbackSkeleton, withMemo } from '@shared/components';
import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, forwardRef } from 'react';
import type { FC, Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WhoIssuesPolicyValues,
  WhoIssuesPolicyOptions,
} from 'smart-components';
import { WhoIssuesPolicyAnalyticEvent } from 'smart-components/who-issues-policy';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';

const analyticEventsMap: AnalyticEventsMap<WhoIssuesPolicyAnalyticEvent> = {
  [WhoIssuesPolicyAnalyticEvent.ON_PHONE_BLUR]: {
    name: analyticEvents.ownerPhoneEntered,
  },
  [WhoIssuesPolicyAnalyticEvent.ON_EMAIL_BLUR]: {
    name: analyticEvents.ownerMailEntered,
  },
  [WhoIssuesPolicyAnalyticEvent.ON_USER_BLUR]: {
    name: analyticEvents.ownerDataEntered,
  },
};

const MemoizedWhoIssuesPolicy = withMemo(WhoIssuesPolicy, [
  'disabled',
  'isSubmitting',
  'value',
  'options',
]);

export const WhoIssuesPolicyAdapter: FC<
  SmartComponentAdapterProps<WhoIssuesPolicyValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { deviceType, phoneFromMobileApp },
          authStore: { disableForm, disableProfileState },
        },
      } = useStores();

      // const isMe = whoIssuesPolicyState?.forMe;

      //TODO: change first traveler's birthdate
      // const handleChangeDate = useCallback(
      //   (newBirthDate: Date) => {
      //     if (isMe && WhoAndHowPaperwork?.travelers) {
      //       const travelers = [...WhoAndHowPaperwork.travelers];
      //       travelers[0] = { ...travelers[0], birthday: newBirthDate };
      //       updateFormValue('WhoAndHowPaperwork', {
      //         ...WhoAndHowPaperwork,
      //         travelers,
      //       });
      //     }
      //   },
      //   [isMe]
      // );

      const options: WhoIssuesPolicyOptions = useMemo(
        () => ({
          deviceType,
          phoneFromMobileApp,
          disableProfileState,
          // onChangeAuthorizedBirthDate: handleChangeDate,
        }),
        [deviceType, phoneFromMobileApp, disableProfileState]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<WhoIssuesPolicyAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense fallback={<FallbackSkeleton height={340} />}>
          <MemoizedWhoIssuesPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
            disabled={disableForm}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

WhoIssuesPolicyAdapter.displayName = 'WhoIssuesPolicyAdapter';
